<template>
    <div class="system-container">
        <el-row>
            <el-button type="primary" size="small" @click="cusReportListExport" plain>导出</el-button>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-input v-model="searchValue" style="width: 200px;float:right;" placeholder="请输入操作人名称进行搜索" @keyup.enter.native="initPage(1)">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="initPage(1)" style="cursor: pointer;"></i>
            </el-input>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table
                :data="userList"
                border
                style="width: 100%"
                :row-style="{height:'20px'}"
                :cell-style="{padding:'2px'}">
                <el-table-column
                    label="序号"
                    align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{((scope.$index+1) + (options.page-1)*options.size)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="operator"
                    label="操作人"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="taTypeName"
                    label="报告类型"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="feeName"
                    label="是否收费"
                    align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-text="scope.row.feeName" :style="{'color':scope.row.feeName=='收费'?'red':'black'}"></span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="operateDate"
                    label="操作日期"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row style="text-align:right; margin-top:30px;">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="turningPage"
                :total="pageTotal">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";
export default {
    name: "userMaintain",
    data(){
        return {
            bufferList:[],
            model: "",
            options: {
                page: 1,
                size: 10,
                parameter: "",
                cusNo: ""
            },
            userList: [],
            pageTotal: 0,
            searchValue: "",
        }
    },
    components: {
    },
    mounted(){
        this.options.cusNo = sessionStorage.cusNo;
        this.initPage();
    },
    methods: {
        initPage(page = 1) {
            this.options.page = page;
            this.options.parameter = this.searchValue;
            api.cusReportList(this.options).then(res => {
                if(res.code == 200 && res.data.rows.length > 0) {
                    this.userList = res.data.rows;
                    this.pageTotal = res.data.total;
                } else {
                    this.userList = [];
                    this.pageTotal = 0;
                }
            });
        },
        turningPage(page) {
            this.initPage(page);
        },
        cusReportListExport(){
            api.cusReportListExport(this.options).then(res => {
                console.log(res);
                if(res.code == 200 && res.data != null) {
                    location.href = constants.baseURL.API + res.data;
                }
            });
        }
    }
}
</script>
<style>
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.system-text {
    font-size: 13px;
}
.el-dropdown-link1 {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
    .system-container .number {
      float: left;
  }
  .system-container .more {
      float: left;
  }
</style>
